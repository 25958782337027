import TouchDevice from './utils/touch-device';

export default class PageTerroirs {
  static init() {
    if (!document.body.classList.contains('page-template-page-terroirs')) return;
    this.initMap();
  }

  static initMap() {
    this.detailsCont = document.querySelector('.terroirsDetails');
    document.querySelector('.terroirsDetails-out').addEventListener('click', () => this.closeDetails());
    Array.prototype.forEach.call(document.querySelectorAll('.terroirsDetails-close'), (closeBtn) => {
      closeBtn.addEventListener('click', () => this.closeDetails());
    })
    document.addEventListener('keydown', event => {
      if (event.keyCode === 27) this.closeDetails();
    });
    Array.prototype.forEach.call(document.querySelectorAll('.enabled-region'), (region) => {
      region.addEventListener('click', () => this.openDetails(region.id));
    })

    if (window.innerWidth < 1000) this.dragMapMobile();

    this.collectID()
    this.checkUrl()
  }

  static collectID() {
    this.regionsID = []
    this.details = document.querySelectorAll('.terroirsDetails-single')

    for (let index = 0; index < this.details.length; index++) {
      const id = this.details[index].id.replace('details-', '')
      this.regionsID.push(id)
    }
  }

  static checkUrl() {
    const search = window.location.search.split('&')[0] // to clear additionals params (region needs to be first)
    const region = search.split('=')[1] // to get the value after the '='

    if (this.regionsID.includes(region)) this.openDetails(region)
  }

  static openDetails(id) {
    this.selectedDetail = document.querySelector('#details-'+id);
    if (this.selectedDetail) {
      document.body.style.overflow = "hidden";
      this.selectedDetail.classList.add('is-selected');
      this.detailsCont.classList.add('is-visible');
    }
  }

  static closeDetails() {
    if (this.detailsCont.classList.contains('is-visible')) {
      document.body.style.overflow = "initial";
      document.querySelector('.terroirsDetails-single.is-selected').classList.remove('is-selected');
      this.detailsCont.classList.remove('is-visible');
    }
  }

  static dragMapMobile() {
    this.draggableMap = document.querySelector('.terroirsMap-inner');
    this.firstPosX = this.draggableMap.getBoundingClientRect().left;
    this.firstPosY = this.draggableMap.getBoundingClientRect().top - document.querySelector('.header').clientHeight;
    this.limitX = (this.draggableMap.offsetWidth - this.draggableMap.parentNode.offsetWidth)/2 + 100;
    this.limitY = (this.draggableMap.offsetHeight - this.draggableMap.parentNode.offsetHeight)/2 + 100;
    if (TouchDevice.isTouchDevice()) {
      this.draggableMap.parentNode.addEventListener('touchstart', (event) => this.startMovingMap(event));
      this.draggableMap.parentNode.addEventListener('touchend', (event) => this.stopMovingMap(event));
    } else {
      this.draggableMap.parentNode.addEventListener('mousedown', (event) => this.startMovingMap(event));
      this.draggableMap.parentNode.addEventListener('mouseup', (event) => this.stopMovingMap(event));
    }
  }

  static startMovingMap(evt) {
    const posX = TouchDevice.isTouchDevice() ? evt.touches[0].pageX : evt.clientX,
          posY = TouchDevice.isTouchDevice() ? evt.touches[0].pageY : evt.clientY,
          divTop = this.draggableMap.getBoundingClientRect().top - document.querySelector('.header').clientHeight + window.scrollY,
          divLeft = this.draggableMap.getBoundingClientRect().left;

    let diffX = posX - divLeft,
        diffY = posY - divTop;

    if (TouchDevice.isTouchDevice()) {
      document.ontouchmove = (evt) => {
        if (!document.body.classList.contains('ov-h')) document.body.classList.add('ov-h');
        if (!this.draggableMap.classList.contains('is-dragging')) this.draggableMap.classList.add('is-dragging');
        evt = evt || window.event;
        const posX = evt.touches[0].pageX,
              posY = evt.touches[0].pageY,
              aX = posX - diffX - this.firstPosX,
              aY = posY - diffY - this.firstPosY;

        if((aX < this.limitX) && (aX > -this.limitX) && (aY < this.limitY) && (aY > -this.limitY)) this.moveMap(this.draggableMap,aX,aY);
      }
    } else {
      document.onmousemove = (evt) => {
        if (!this.draggableMap.classList.contains('is-dragging')) this.draggableMap.classList.add('is-dragging');
        evt = evt || window.event;
        const posX = evt.clientX,
              posY = evt.clientY,
              aX = posX - diffX - this.firstPosX,
              aY = posY - diffY - this.firstPosY; 
        if((aX < this.limitX) && (aX > -this.limitX) && (aY < this.limitY) && (aY > -this.limitY)) this.moveMap(this.draggableMap,aX,aY);
      }
    }
  }

  static moveMap(el,xpos,ypos) {
    el.style.left = xpos + 'px';
    el.style.top = ypos + 'px';
  }

  static stopMovingMap() {
    this.draggableMap.classList.remove('is-dragging');
    document.body.classList.remove('ov-h');
    const a = document.createElement('script');
    TouchDevice.isTouchDevice() ? document.ontouchmove = () => {} : document.onmousemove = () => {};
  }
}
