import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Parallaxes {
  static init(){
    Array.prototype.forEach.call(document.querySelectorAll('.inner-parallax'), (el) => this.innerParallax(el));
    Array.prototype.forEach.call(document.querySelectorAll('.hero-parallax'), (el) => this.heroParallaxes(el));
  }

  static innerParallax(el) {
    const scroller = window;
    const delta = el.dataset.parallax / 2;
    gsap.fromTo(el.querySelector('img'), 
      {
      y:'-'+delta+'%'
      // y:'-'+delta*10+'px'
      }, 
      {
      scrollTrigger: {
        trigger: el,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        scroller: scroller
      },
      y:delta+'%', 
      //y:delta*10+'px', 
      force3D:true,
      immediateRender:true
    });
  }

  static heroParallaxes(el) {
    gsap.to(el, {
      scrollTrigger: {
        trigger: el,
        start: "top top",
        end: 'bottom top',
        scrub: true
      },
      y: '200px',
      ease:"none"
    });
  }
}
