import gsap from 'gsap';
import ScrollReveals from "./scroll-reveals";

export default class FiltersVins {
  static init() {
    if (!document.querySelector('.vinsFilters')) return

    this.computeData()
    this.bindEvents()
  }

  static computeData() {
    this.list = document.querySelector('.vinsArchives')
    this.listItems = document.querySelectorAll('.vinsArchives-item')

    this.mobileBtn = document.querySelector('.vinsFilters-mobile')
    this.mobileClose = document.querySelector('.vinsFilters-close')
    this.container = document.querySelector('.vinsFilters')
    this.allTax = document.querySelectorAll('.vinsFilters-tax')
    this.allTerms = document.querySelectorAll('.vinsFilters-term')
    this.background = document.querySelector('.vinsFilters-bg')

    // Get dynamically all tax
    this.taxonomies = []
    for (var i = this.allTerms.length - 1; i >= 0; i--) {
      const currentTax = this.allTerms[i].dataset.tax
      if(!this.taxonomies.includes(currentTax)) this.taxonomies.push(currentTax)
    }

    // Build dynamically selectedTerms object
    this.selectedTerms = {}
    for (var i = 0; i < this.taxonomies.length; i++) {
      this.selectedTerms[this.taxonomies[i]] = []
    }
  }

  static bindEvents() {
    Array.prototype.forEach.call(document.querySelectorAll('.vinsFilters-name'), (el) => el.addEventListener('click', () => this.toggleTax(el.parentNode)))

    Array.prototype.forEach.call(this.allTerms, (term) => {
      term.addEventListener('click', () => this.updateSelectedTerms(term) );
      term.querySelector('.checkbox-container input').addEventListener('click', (event) => event.stopPropagation() );
    });

    this.mobileBtn.addEventListener('click', () => this.openFilters());
    this.background.addEventListener('click', () => this.hideFilters());
    this.mobileClose.addEventListener('click', () => this.hideFilters());

    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) this.escapeTax();
    });

    window.addEventListener('click', (e) => {
      if (e.target.tagName != "A" && !e.target.closest('.vinsFilters')) this.escapeTax();
    })
  }

  static openFilters() {
    this.container.classList.add('is-open');
  }
  static hideFilters() {
    this.container.classList.remove('is-open');
  }

  static toggleTax(el) {
    el.classList.contains('is-open') ? this.closeTax(el) : this.openTax(el);
  }

  static openTax(el) {
    this.escapeTax();
    el.classList.add('is-open');
    this.adjustBackground(el); 
  }

  static escapeTax() {
    if (this.container.querySelector('.vinsFilters-tax.is-open')) this.closeTax(this.container.querySelector('.vinsFilters-tax.is-open'));
  }

  static closeTax(el) {
    el.classList.remove('is-open');
    this.container.querySelector('.vinsFilters-tax.is-open') ? this.adjustBackground(this.container.querySelector('.vinsFilters-tax.is-open')) : this.resetBackground();
  }

  static adjustBackground(el) {
    if (window.innerWidth < 700) return
    this.background.style.height = el.clientHeight+"px";
  }

  static resetBackground() {
    if (window.innerWidth < 700) return
    this.background.style.height = this.container.clientHeight+"px";
  }

  static updateSelectedTerms(term) {
    if (term.classList.contains('is-active')) {
      this.removeTerm(term.querySelector('input').id, term.dataset.tax)
    } else {
      this.removeAllTermsFromTax(term.dataset.tax)
      this.addTerm(term.querySelector('input').id, term.dataset.tax)
    }

    term.classList.toggle('is-active')

    this.hideList().then(() => this.filter())
  }

  static addTerm(slug, tax) {
    if (!this.selectedTerms[tax].includes(slug)) this.selectedTerms[tax].push(slug)
  }

  static removeTerm(slug, tax) {
    const index = this.selectedTerms[tax].indexOf(slug)
    if (index > -1) this.selectedTerms[tax].splice(index, 1);
  }

  static removeAllTermsFromTax(tax) {
    this.selectedTerms[tax] = []

    Array.prototype.forEach.call(this.allTerms, (term) => {
      if (term.dataset.tax == tax) {
        term.querySelector('input').checked = false
        term.classList.remove('is-active')
      }
    })
  }

  static hideList() {
    return new Promise((resolve) => {
      gsap.to(this.list, {opacity: 0, duration:0.1, onComplete: () => resolve()})
    });
  }

  static showList() {
    gsap.to(this.list, {opacity:1, duration:0.3, delay:0.1});
  }

  static filter() {
    this.visibleItems = 0;

    for (let indexItem = this.listItems.length - 1; indexItem >= 0; indexItem--) {

      let isItemEnable = true

      for (let indexTax = this.taxonomies.length - 1; indexTax >= 0; indexTax--) {
        const currentTax = this.taxonomies[indexTax]
        const itemTax = this.listItems[indexItem].getAttribute('data-'+currentTax)

        if (this.selectedTerms[currentTax].length > 0 && !this.selectedTerms[currentTax].includes(itemTax)) {
          isItemEnable = false
          break;
        }
      }

      if (isItemEnable) {
        this.listItems[indexItem].classList.remove('is-hidden');
        this.visibleItems++;
      } else {
        this.listItems[indexItem].classList.add('is-hidden');
      }
    }

    this.showList()
    this.visibleItems > 0 ? this.hideNoResults() : this.showNoResults();

    ScrollReveals.refresh()
  }

  static showNoResults() {
    document.querySelector('.vinsArchives-noResults').classList.remove('is-hidden');
  }

  static hideNoResults() {
    document.querySelector('.vinsArchives-noResults').classList.add('is-hidden');
  }
}
