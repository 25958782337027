import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { easeTitleReveal } from './utils/constants';
gsap.registerPlugin(ScrollTrigger);

export default class QuoteFull {
  static init() {
    if (!document.querySelector('.quoteFull')) return;
    // setTimeout( () => this.textReveal(), 200);
  }

  static textReveal() {
    gsap.fromTo('.quote div', {
      rotationX:'90deg',
      x:'0.3em'
      }, 
      {
      scrollTrigger: {
        trigger: '.quote',
        start: "top 75%",
      },
      rotationX:'0deg', 
      x:0, 
      force3D:true, 
      duration: .8,
      stagger:0.01,
      ease: easeTitleReveal
    });
  }
}
