// import { gsap } from 'gsap';

export default class Header {
  static init() {
    this.header = document.querySelector('.header');
    this.menuButton = this.header.querySelector('.menu-btn');
    this.isMenuVisible = false;
    this.menuButton.addEventListener('click', () => this.toggleMenu());

    window.addEventListener("scroll", () => this.scrolling(), false);
  }

  static scrolling() {
    if (document.body.classList.contains('page-template-page-accueil')) {
      document.querySelector('.hpSlider').getBoundingClientRect().bottom < this.header.clientHeight ? this.headerTransparent() : this.headerSolid();
    }
  }

  static headerTransparent() {
    if (!this.header.classList.contains('solid')) this.header.classList.add('solid');
  }

  static headerSolid() {
    if (this.header.classList.contains('solid')) this.header.classList.remove('solid');
  }

  static toggleMenu() {
    !this.isMenuVisible ? this.showMenu() : this.hideMenu();
  }

  static showMenu() {
    this.isMenuVisible = true;
    this.header.classList.add('is-open');
    document.body.style.overflow = "hidden";
  }

  static hideMenu() {
    this.isMenuVisible = false;
    this.header.classList.remove('is-open');
    document.body.style.overflow = "auto";
  }
}
