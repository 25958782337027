import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { easeTitleReveal } from './utils/constants';
gsap.registerPlugin(ScrollTrigger);

export default class ScrollReveals {
  static init() {
    this.yDelta = window.innerWidth < 700 ? '98%' : '85%';
    Array.prototype.forEach.call(document.querySelectorAll('.scroll-reveal[data-split]'), (string) => {
      setTimeout(() => this.scrollRevealTitle(string), 200);
    });

    Array.prototype.forEach.call(document.querySelectorAll('.fade-reveal'), (el) => {
      setTimeout(() => this.fadeReveal(el), 200);
    });

    if (document.querySelector('.shapeLine')) {
      gsap.to('.shapeLine svg', { 
          scrollTrigger: { 
            trigger: '.shapeLine', 
            start: "top 50%",
            onEnter: () => {document.querySelector('.shapeLine').classList.add('is-visible')}
         }
        }
      );
    }
  }

  static scrollRevealTitle(string) {
    if (string.classList.contains('split-hidden')) string.classList.remove('split-hidden');
    let scroller = window;
    if (string.closest('.terroirsDetails')) scroller = window.innerWidth < 1000 ? string.closest('.terroirsDetails') : string.closest('.terroirsDetails-single');
    gsap.fromTo(string.querySelectorAll('div'), 
      {rotationX:'90deg', x:'0.3em'}, 
      { 
        scrollTrigger: { trigger: string, start: "top "+this.yDelta, scroller: scroller, immediateRender:true },
        rotationX:'0deg', x:0, force3D:true, duration: 1, stagger:0.05, ease: easeTitleReveal
      }
    );
  }

  static fadeReveal(el) {
    let scroller = window;
    let delay;
    el.dataset.delay ? delay = el.dataset.delay / 10 : delay = 0;
    if (el.closest('.terroirsDetails')) scroller = string.closest('.terroirsDetails-single');
    gsap.fromTo(el, 
      { opacity:0, y: '50px'}, 
      { 
        scrollTrigger: { trigger: el, start: "top "+this.yDelta, scroller: scroller, immediateRender:true },
        opacity: 1, y: '0px', force3D:true, duration: 1, delay: delay, ease: easeTitleReveal
      }
    );
  }

  static refresh() {
    ScrollTrigger.refresh(true)
  }
}
