import { ready } from './js/utils/utils';
import TouchDevice from './js/utils/touch-device';
import Legals from './js/utils/legals';
import Form from './js/utils/form';
import Header from './js/header';
import CardVins from './js/card-vins';
import SearchForm from './js/search-form';
import PageAccueil from './js/page-accueil';
import PageTerroirs from './js/page-terroirs';
import SingleVins from './js/single-vins';
import SplitText from './js/split-text';
import QuoteFull from './js/quote-full';
import ScrollReveals from './js/scroll-reveals';
import Parallaxes from './js/parallaxes';
import Sliders from './js/utils/sliders';
import FiltersVins from './js/filters-vins';

ready()
  .then(() => {
    SplitText.init()
    TouchDevice.init()
    Legals.init()
    Form.init()
    ScrollReveals.init()
    Parallaxes.init()
    Header.init()
    CardVins.init()
    PageAccueil.init()
    PageTerroirs.init()
    SingleVins.init()
    QuoteFull.init()
    new SearchForm()
    Sliders.init()
    FiltersVins.init()
  });
