export default class Form {
  static init() {
    Array.prototype.forEach.call(document.querySelectorAll('input, textarea'), (el) => this.keyupForm(el));
  }

  /* To specify style on focused / filled input */
  static keyupForm(el) {
    el.addEventListener('keyup', () => {
      if (el.value === '') {
        el.classList.remove('active');
      } else if( !el.classList.contains('active') ) { 
        el.classList.add('active'); 
      }
    });
  }
}
