export default class SearchForm {
  constructor() {
    this.container = document.querySelector('.header-right');
    this.trigger = document.querySelector('.site-search');
    this.input = document.querySelector('.site-search #search');
    this.trigger.addEventListener('click', (e) => this.openSearch(e));
    window.addEventListener('keydown', (e) => {
      if (e.keyCode == 27) this.closeSearch();
    })
  }

  openSearch(e) {
    if (this.container.classList.contains('search-open') || window.innerWidth < 1000) return;
    e.preventDefault();
    this.container.classList.add('search-open');
    this.input.focus();
    this.clickHandler = this.outsideClickListener.bind(this);
    document.addEventListener('click', this.clickHandler);
  }

  outsideClickListener(e) {
    if (this.container.contains(e.target)) return;
    this.closeSearch();
    this.removeClickListener();
  }

  removeClickListener() {
    document.removeEventListener('click', this.clickHandler);
  }

  closeSearch() {
    if (window.innerWidth < 1000) return;
    if (this.container.classList.contains('search-open')) this.container.classList.remove('search-open');
  } 
}

