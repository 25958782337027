import gsap from 'gsap';

export default class CardVins {
  static init() {
    if(!document.querySelector('.vinCard')) return;

    this.container = document.querySelectorAll('.vinCard')[0].parentNode.parentNode;
    this.mousePos = {x: 0, y: 0};
    this.translate = {x: 0, y: 0};
    this.rafThumb = null;

    if (!document.body.classList.contains('touch-device')) {
      this.container.addEventListener('mouseover', (e) => {
        if(e.target.classList.contains('vinCard-thumb')) this.zoomIn(e.target);
      })
    }
  }

  static zoomIn(thumb) {
    this.thumb = thumb;
    this.thumbImg = thumb.querySelector('img');
    gsap.set(this.thumbImg, { scale:4 });

    this.thumbImgSize = {
      x: this.thumbImg.getBoundingClientRect().width, 
      y: this.thumbImg.getBoundingClientRect().height
    };

    this.thumbSize = {
      x: thumb.getBoundingClientRect().width, 
      y: thumb.getBoundingClientRect().height
    };

    thumb.addEventListener('mousemove', (e) => this.cursorPos(e));
    thumb.addEventListener('mouseleave', () => this.unFollowThumb(), {once:true});

    gsap.fromTo(this.thumbImg, {opacity:0}, {opacity:1, duration:0.3});

    this.followThumb();
  }

  static unFollowThumb() {
    gsap.set(this.thumbImg, { scale:1, x:0, y:0 });
    gsap.fromTo(this.thumbImg, {opacity:0}, {opacity:1, duration:0.3});
    cancelAnimationFrame(this.rafThumb); 
    this.rafThumb = null;
  }

  static followThumb() {
    this.rafThumb = requestAnimationFrame(() => this.followThumb());

    let translate = {
      x: - (this.thumbImgSize.x - this.thumbSize.x) * this.mousePos.x,
      y: - (this.thumbImgSize.y - this.thumbSize.y) * this.mousePos.y
    }

    gsap.set(this.thumbImg, { x:translate.x, y:translate.y });
  }

  static cursorPos(e) {
    this.mousePos = {
      x: (e.pageX - this.thumb.getBoundingClientRect().left) / this.thumbSize.x - 0.5, 
      y: (e.pageY - window.scrollY - this.thumb.getBoundingClientRect().top) / this.thumbSize.y - 0.5
    };
  }
}
