// import Newsletter from './newsletter';

export default class Legals {
  static init() {
    this.disclaimerAge = document.querySelector('.disclaimer.age');
    // this.disclaimerCookies = document.querySelector('.disclaimer.cookies');
    this.initDisclaimerAge();
  }

  static initDisclaimerAge() {
    if (sessionStorage.getItem('legalAge_signoreguiseppe')) {
      // this.initDisclaimerCookies();
      // Newsletter.init();
      return;
    }
    document.body.style.overflow = "hidden";
    this.disclaimerAge.classList.add('is-visible');
    document.getElementById('accept-age').addEventListener('click', () => this.acceptDisclaimerAge());
  }

  static acceptDisclaimerAge() {
    sessionStorage.setItem('legalAge_signoreguiseppe', 'ok');
    this.disclaimerAge.classList.remove('is-visible');
    document.body.style.overflow = "initial";
    // this.initDisclaimerCookies();
    // Newsletter.init();
  }

  /*
  static initDisclaimerCookies() {
    if (localStorage.getItem('cookies_signoreguiseppe')) {
      Newsletter.init();
      return;
    }
    this.disclaimerCookies.classList.add('is-visible');
    document.getElementById('accept-cookies').addEventListener('click', () => this.acceptDisclaimerCookies());
  }

  static acceptDisclaimerCookies() {
    localStorage.setItem('cookies_signoreguiseppe', 'ok');
    this.disclaimerCookies.classList.remove('is-visible');
    Newsletter.init();
  }
  */
}
