import gsap from 'gsap';
import { animPlaying, toggleAnimPlaying, easeVolet, easeTitleReveal } from './utils/constants';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class SingleVins {
  static init() {
    if (!document.body.classList.contains('single-vins')) return;
    if (document.querySelector('.vinRelated')) this.vinRelated();
  }

  static vinRelated() {
    gsap.fromTo('.vinRelated-thumb-inner', {
      y:'20%'
    },
    {
      scrollTrigger: {
        trigger: '.vinRelated-thumb-inner',
        start: "top bottom",
        end: 'bottom top',
        scrub: true
      },
      immediateRender: true,
      y: '-20%',
      ease:"none"
    });
  }
}
