import { tns } from 'tiny-slider/src/tiny-slider';

export default class Sliders {
  static init() {
    if (document.querySelector('.o-slider') == null) return;
    this.sliders = document.querySelectorAll('.o-slider');
    for (let i = this.sliders.length - 1; i >= 0; i--) { this.initSlider(this.sliders[i]) }
  }

  static initSlider(slider) {
    let gutter = 0,
        itemsL = 4,
        itemsM = 2,
        itemsS = 1;

    if (slider.classList.contains('vinsSlider-slide')) {
      itemsL = 3
    } else if (slider.classList.contains('histSlider-slider')) {
      itemsL = 1
    }

    if (slider.classList.contains('histSlider-slider')) { itemsS = 1.1; itemsM = 1  }
    if (slider.classList.contains('histSlider-slider')) gutter = 20

    if (slider.closest('.terroirsDetails')) {
      itemsL = 2
      itemsM = 2
    }

    const controls = slider.classList.contains('vinsSlider-slide') ? true : false

    const tnsSlider = tns({
      container: slider,
      gutter: gutter,
      loop: false,
      autoplay: false,
      mouseDrag: true,
      nav: false,
      controls: controls,
      controlsContainer: slider.parentNode.querySelector('.vinsSlider-nav'),
      responsive: {
        0: { items: itemsS },
        700: { items: itemsM },
        1000: { items: itemsL }
      }
    });

    const info = tnsSlider.getInfo();
    
    if (slider.closest('.vinsSlider')) {
      this.isProgress = true
      this.computePercentage(tnsSlider)
      this.currentPercentage >= 1 ? this.removeProgress(slider) : this.updateProgress(slider, tnsSlider)
      tnsSlider.events.on('transitionStart', () => this.updateProgress(slider, tnsSlider));
    }

    tnsSlider.events.on('dragMove', () => Array.prototype.forEach.call(info.slideItems, (slide) => slide.style.pointerEvents = "none") );
    tnsSlider.events.on('dragEnd', () => Array.prototype.forEach.call(info.slideItems, (slide) => slide.style.pointerEvents = "all") );

  }

  static computePercentage(tnsSlider) {
    this.currentPercentage = (tnsSlider.getInfo().index + tnsSlider.getInfo().items) / tnsSlider.getInfo().slideCount
  }

  static updateProgress(slider, tnsSlider) {
    this.computePercentage(tnsSlider)
    slider.closest('.vinsSlider').querySelector('.vinsSlider-progressline').style.transform = "scaleX("+this.currentPercentage+")"
  }

  static removeProgress(slider) {
    slider.closest('.vinsSlider').querySelector('.vinsSlider-progress').style.display = "none"
    this.isProgress = false
  }
}


