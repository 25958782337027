import gsap from 'gsap';
import { animPlaying, toggleAnimPlaying, easeVolet, easeTitleReveal } from './utils/constants';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class PageAccueil {
  static init() {
    if (!document.body.classList.contains('page-template-page-accueil')) return;
    this.sliderInit();
    this.parallaxes();
  }

  static sliderInit() {
    this.slider = document.querySelector('.hpSlider-slider');
    this.navItems = document.querySelectorAll('.hpSlider-navItem');
    this.muted = true;
    Array.prototype.forEach.call(this.navItems, (item) => {
      item.addEventListener('click', () => this.sliderNext(item.dataset.index));
    })
    this.soundBtn = document.querySelector('.enable-sound');
    this.soundBtn.addEventListener('click', () => { this.muted ? this.enableSound() : this.disableSound() });

    // Make sound button visible
    document.querySelector('.hpSlider-slide.is-active').classList.contains('video') ? this.showSoundBtn() : this.hideSoundBtn();

    gsap.fromTo(this.slider.querySelectorAll('.hpSlider-slide.is-active .hpSlider-title div'), {rotationX:'90deg', x:'0.3em'}, {rotationX:'0deg', x:0, force3D:true, duration: .8, ease:easeTitleReveal, stagger:0.01, delay:0.5});
    setTimeout(() => this.slider.querySelector('.hpSlider-slide.is-active .hpSlider-copy').classList.add('is-visible'), 500);
  }

  static sliderNext(index) {
    if (animPlaying) return;
    toggleAnimPlaying(true);
    const nextSlide = this.slider.querySelector('.hpSlider-slide:nth-of-type('+index+')');
    const oldSlide = this.slider.querySelector('.hpSlider-slide.is-active');

    // Make sound button visible / hidden
    nextSlide.classList.contains('video') ? this.showSoundBtn() : this.hideSoundBtn();
    if (!this.muted && this.slider.querySelector('.hpSlider-slide.is-active video')) this.muteVideo(this.slider.querySelector('.hpSlider-slide.is-active video'));
    if (!this.muted && nextSlide.querySelector('video')) this.unMuteVideo(nextSlide.querySelector('video'));

    // Update nav
    document.querySelector('.hpSlider-navItem.is-active').classList.remove('is-active');
    document.querySelector('.hpSlider-navItem[data-index="'+index+'"]').classList.add('is-active');

    oldSlide.classList.add('is-leaving');
    nextSlide.classList.add('is-active');
    nextSlide.style.zIndex = 3;

    // old slide
    gsap.fromTo(oldSlide, {y: '0%'}, {y:'-10%', duration: 1.4, ease:easeVolet});

    // New slide
    gsap.set(nextSlide, {y:'0%'});
    const tlNewSlide = gsap.timeline();

    tlNewSlide.fromTo(nextSlide.querySelector('.hpSlider-background'), {y: '100%'}, { y:'0%', ease:easeVolet, duration: 1.4 })
      .fromTo(nextSlide.querySelector('.hpSlider-media'), {y: '-90%', scale: 1.2}, { y:'0%', scale:1, duration: 1.4, ease:easeVolet },"-=1.4")
      .add(() => {
        oldSlide.classList.remove('is-active', 'is-leaving');
        nextSlide.style.zIndex = 'initial';
        oldSlide.querySelector('.hpSlider-copy').classList.remove('is-visible');
        nextSlide.querySelector('.hpSlider-copy').classList.add('is-visible');
        toggleAnimPlaying(false);
      })
      .fromTo(nextSlide.querySelectorAll('.hpSlider-title div'), {rotationX:'90deg', x:'0.3em'}, {rotationX:'0deg', x:0, force3D:true, duration: .8, ease:easeTitleReveal, stagger:0.01},"-=0.8");
  }

  static showSoundBtn() {
    if (!this.soundBtn.classList.contains('is-visible')) this.soundBtn.classList.add('is-visible');
  }

  static hideSoundBtn() {
    if (this.soundBtn.classList.contains('is-visible')) this.soundBtn.classList.remove('is-visible');
  }

  static enableSound() {
    this.muted = false;
    this.soundBtn.classList.add('is-enable');
    const currentVideo = this.slider.querySelector('.hpSlider-slide.is-active video');
    if (currentVideo) this.unMuteVideo(currentVideo);
  }

  static disableSound() {
    this.muted = true;
    this.soundBtn.classList.remove('is-enable');
    const currentVideo = this.slider.querySelector('.hpSlider-slide.is-active video');
    if (currentVideo) this.muteVideo(currentVideo);
  }

  static muteVideo(video) {
    let vol = 1;
    const fadeOut = setInterval(() => {
      if (vol > 0 ) {
        vol -= 0.05;
        video.volume = vol.toFixed(2);
      } else {
        clearInterval(fadeOut);
        video.muted = true;
      }
    }, 20);
  }

  static unMuteVideo(video) {
    let vol = 0;
    video.muted = false;
    video.volume = 0;
    const fadeIn = setInterval(() => {
      if (vol < 1 ) {
        vol += 0.05;
        video.volume = vol.toFixed(2);
      } else {
        clearInterval(fadeIn);
      }
    }, 50);
  }

  static parallaxes() {
    Array.prototype.forEach.call(document.querySelectorAll('.hpImgText-img.small'), (img) => {
      gsap.to(img, {
        scrollTrigger: {
          trigger: img,
          start: "top bottom",
          end: 'bottom top',
          scrub: true
        },
        y: '-200px',
        ease:"none"
      });
    })
  }

}
