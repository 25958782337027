import { gsap } from 'gsap';
import { CustomEase } from "./../custom-ease";
gsap.registerPlugin(CustomEase);

let animPlaying = false;
const toggleAnimPlaying = (bool) => { animPlaying = bool }
export { animPlaying, toggleAnimPlaying };

let firstLaunch = true;
const toggleFirstLaunch = () => { firstLaunch = false }
export { firstLaunch, toggleFirstLaunch };

const easeVolet = CustomEase.create("volet", "0.5, 0, 0, 1");
const easeMaskVolet = CustomEase.create("maskVolet", "0.70, 0, 0.01, 1");
const easeTitleReveal = CustomEase.create("titleReveal", ".19,1,.22,1");
const homeReveal = CustomEase.create("homeReveal", "0.11, 0.4, 0, 1");
export { easeVolet, homeReveal, easeMaskVolet, easeTitleReveal };
